.info {
    animation: fadeIn 2s;
    margin: 0;
    
    height: 360px;
    width: 100%;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.info .name {
    font-size: 35px;
    font-weight: 400;
    color: #60f6d5;

    margin-bottom: 5px;
}

.info .projectInfo {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 50px;
}

.info .projectPic {
    width: 90vw;
    min-width: 400px;
}

.info .description {
    font-size: 19px;
    font-weight: 400;

    margin-bottom: 20px;
    color: #8993AE;

    padding-left: 15px;
}

.info .listedItems {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    margin-bottom: 15px;
}

.info .itemTitle {
    font-size: 24px;
    font-weight: 400;
    
    color: #c2cff7;

    padding-left: 15px;
}

.info .listItem {
    font-size: 18px;
    font-weight: 400;
    color: #60f6d5;

    border: solid 1px #60f6d5;
    padding: 6px;
    border-radius: 10px;

    text-decoration: none;

    cursor: pointer;
}