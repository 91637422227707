#experienceTimeline {
    position: relative;

    width: 19%;
    aspect-ratio: 0.37;

    min-width: 137px;
    max-height: 377px;
    max-width: 300px;
}

.timelineLogoCompleted {
    width: 100%;
    position: absolute;
    display: inline-block;
    cursor: pointer;
    
    filter: opacity(1);
}

.timelineLogoIncomplete {
    width: 100%;
    position: absolute;
    display: inline-block;
    cursor: pointer;
    
    filter: opacity(0.3);
}

.timelineLogoAnimatedNext {
    width: 100%;
    position: absolute;
    display: inline-block;
    cursor: pointer;

    animation: colorIn 800ms;
}

.timelineLogoAnimatedBack {
    width: 100%;
    position: absolute;
    display: inline-block;
    cursor: pointer;

    animation: colorOut 800ms;
}

.timelineContainer {
    position: absolute;
    display: inline-block;
    /* background-color: red; */
}

@keyframes colorIn {
    0% {
        filter: opacity(0.3);
        offset-distance: 0%;
    }
    100% {
        filter: opacity(1);
        offset-distance: 100%;
    }
}

@keyframes colorOut {
    0% {
        filter: opacity(1);
        offset-distance: 0%;
    }
    100% {
        filter: opacity(0.3);
        offset-distance: 100%;
    }
}

@property --progress-value {
    syntax: '<integer>';
    inherits: false;
    initial-value: 0;
  }

  @keyframes css-progress {
    to { --progress-value: 100; }
  }

.circleBarCompleted {
    position: absolute;
    display: inline-block;
    cursor: pointer;

    width: 44%;
    aspect-ratio: 1;

    border-radius: 50%;
    background: 
      radial-gradient(closest-side, #0a192f 88%, transparent 90% 100%),
      conic-gradient(#60f6d5 100%, #8993AE 0);
}

.circleBarIncomplete {
    position: absolute;
    display: inline-block;
    cursor: pointer;

    width: 44%;
    aspect-ratio: 1;

    border-radius: 50%;
    background: 
      radial-gradient(closest-side, #0a192f 88%, transparent 90% 100%),
      conic-gradient(#60f6d5 0, #8993AE 0);
}

.circleBarAnimatedNext {
    position: absolute;
    display: inline-block;
    cursor: pointer;

    width: 44%;
    aspect-ratio: 1;

    border-radius: 50%;
    background: 
      radial-gradient(closest-side, #0a192f 88%, transparent 90% 100%),
      conic-gradient(#60f6d5 calc(var(--progress-value) * 1%), #8993AE 0);

    counter-reset: percentage var(--progress-value);
    animation: css-progress 0.8s linear forwards;
}

.circleBarAnimatedBack {
    position: absolute;
    display: inline-block;
    cursor: pointer;

    width: 44%;
    aspect-ratio: 1;

    border-radius: 50%;
    background: 
      radial-gradient(closest-side, #0a192f 88%, transparent 90% 100%),
      conic-gradient(#60f6d5 calc((100 - var(--progress-value)) * 1%), #8993AE 0);

    counter-reset: percentage var(--progress-value);
    animation: css-progress 0.8s linear forwards;
}

.bridgeBarCompleted {
    position: absolute;
    display: inline-block;

    height: 1%;
    width: 39.1%;
    
    background-image: 
        linear-gradient(to right, RGB(137,147,174), RGB(137,147,174)),
        linear-gradient(to right, RGB(96,246,213), RGB(96,246,213));
    background-size: 0% 6px, 100% 6px;
    background-position: 100% 0%, 0% 0%;
    background-repeat: no-repeat;
}

.bridgeBarAnimatedNext {
    position: absolute;
    display: inline-block;

    height: 1%;
    width: 39.1%;

    background-image: 
        linear-gradient(to right, RGB(137,147,174), RGB(137,147,174)),
        linear-gradient(to right, RGB(96,246,213), RGB(96,246,213));
    background-position: 100% 0%, 0% 0%;
    background-repeat: no-repeat;

    animation: progressForward 200ms;
}

.bridgeBarAnimatedBack {
    position: absolute;
    display: inline-block;
    
    height: 1%;
    width: 39.1%;

    background-image: 
        linear-gradient(to right, RGB(137,147,174), RGB(137,147,174)),
        linear-gradient(to right, RGB(96,246,213), RGB(96,246,213));
    background-position: 100% 0%, 0% 0%;
    background-repeat: no-repeat;

    animation: progressBackward 200ms;
}

.bridgeBarIncomplete {
    position: absolute;
    display: inline-block;

    height: 1%;
    width: 39.1%;

    background-image: 
        linear-gradient(to right, RGB(137,147,174), RGB(137,147,174)),
        linear-gradient(to right, RGB(96,246,213), RGB(96,246,213));
    background-size: 100% 6px, 0 6px;
    background-position: 100% 0%, 0% 0%;
    background-repeat: no-repeat;
}

@keyframes progressForward {
    0% {
        background-size: 100% 6px, 0 6px;
        offset-distance: 0%;
    }
    100% {
        background-size: 0 6px, 100% 6px;
        offset-distance: 100%;
    }
}

@keyframes progressBackward {
    0% {
        background-size: 0 6px, 100% 6px;
        offset-distance: 0%;
    }
    100% {
        background-size: 100% 6px, 0 6px;
        offset-distance: 100%;
    }
}