#contactContent {
    padding-top: 10%;
    min-height: 100%
}

.logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    transition: scale .2s ease-in-out; 
}

.logo:hover {
    scale: 1.05;
}

.logo a {
    display: block;
    all: unset;
}

.logo p{
    font-size: 13pt;
    margin-top: 12px;
}

.logo-icon {
    cursor: pointer;
}

@keyframes changeIconColor {
    0% {
        offset-distance: 0%;
        fill: #c2cff7;
    }
    100% {
        offset-distance: 100%;
        fill: #60f6d5;
    }
}

@keyframes changeText {
    0% {
        offset-distance: 0%;
        opacity: 0;
    }
    100% {
        offset-distance: 100%;
        opacity: 1;
    }
}
