#projectsBox {
    flex-direction: column;
}

.projectRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project {
    height: 160px;
    width: 160px;
    border: 2px solid #60f6d5;
    border-radius: 10%;
    display: inline-block;
    margin: 16px;
    transition: all .2s ease-in-out;
}

.projectBox {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.project:hover {
    border: 4px solid #60f6d5;
    margin: 14px;
}

.project-icon {
    color: #60f6d5;
    margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    height: 70px;
    width: 70px;
}

.card-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    border-radius: 10%;
}

.card-wrapper:hover .card-bottom {
    transform: translate(0%, -50%);
    opacity: 1;
    transition: all 0.3s ease-in-out;
    background-color: rgba(39, 40, 39, 0.9);
}

.card-top {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.card-bottom {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 20;
    top: 50%;
    color: #fff;
    transform: translateY(-50%);
    opacity: 0;
    text-align: center;

    transition: all 0.7s ease-in-out;
}

.top-text {
    color: #e3e7f1;
    font-size: 20px;

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.back {
    display: inline-block;
    color: #60f6d5;
    border: 2px solid #60f6d5;
    border-radius: 10%;
    margin-left: 7%;
    padding: 5px 10px;
}

.projectTitle {
    color: #c2cff7;
    margin-top: 0;
}

.projectDescription {
    font-size: 20px;
}