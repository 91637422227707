#experienceContent {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 20px;
    gap: 50px
}

.info {
    animation: fadeIn 2s;
    margin-left: 100px;
    
    height: 360px;
    width: 100%;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.timeline {
    flex-direction: column;
}

.timelineItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companyClick {
    padding: 2px;
    margin: 5px;
    border: 2px solid red;

    cursor: pointer;
}

.vl {
    border-left: 3px solid red;
    height: 25px;
}

.info .name {
    font-size: 35px;
    font-weight: 400;
    color: #60f6d5;

    margin-bottom: 5px;
}

.info .jobTitle {
    font-size: 22px;
    font-weight: 400;

    padding-left: 30px;
    margin-bottom: 5px;
}

.info .setting {
    font-size: 22px;
    font-weight: 400;

    padding-left: 30px;
    margin-bottom: 20px;
}

.info .description {
    font-size: 17px;
    font-weight: 400;

    margin-bottom: 20px;
}

.info .rolesText {
    font-size: 17px;
    font-weight: 400;
}

.info .roles {
    font-size: 17px;
    font-weight: 400;

    margin-top: 0px;
}

.info .roles {
    font-size: 17px;
    font-weight: 400;
}

.info .roles .roleList {
    font-size: 17px;
    font-weight: 400;

    margin-top: 0px;
}