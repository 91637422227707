.mobileContent {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    
    align-items: center;
    justify-content: center;

    flex-direction: column;
}

.mobileTitle {
    color: #c2cff7;
    margin-top: 0;
    margin-bottom: 0;
}

.mobileText {
    font-size: 16px;
    color: #8993ae;
}

.continueButton {
    border: #60f6d5 1px solid;
    border-radius: 10px;
    color: #60f6d5;

    padding: 10px;
    font-size: 14px;

    transition: all .2s ease-in-out; 
}

.continueButton:hover {
    border: #60f6d5 2px solid;
}