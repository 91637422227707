.App {
    height: 100vh;
    width: 100%;
    background-color: #0a192f;
}

.content {
    color: #8993ae;
    max-width: 1200px;
    width: 80%;
    height: 50vh;
    margin: 0 auto;
}

.highlight {
    color: #60f6d5;
}

.title {
    color: #c2cff7;
    margin-top: 0;
}

.fade-enter {
    opacity: 0;
    transform: translate(0, 0px);
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translate(0, 0);

    transition: opacity 1500ms ease-out;
}