.IconDisplay {
    display: inline-block;
    position: relative;
    height: 200px;
    width: 530px;
}

.IconNavigator {
    position: fixed; 
    bottom:0%;
    width: 100%;

    text-align: center;
    margin: 0 auto;
}

.dot {
    border: 2px solid #60f6d5;
    border-radius: 50%;
    display: inline-block;
    
    text-align: center;
    vertical-align: middle;
    position: absolute;
    cursor: pointer;
    transition: all .2s ease-in-out; 
}

.dot:hover {
    border: 4px solid #60f6d5;
}

.icons {
    color: #60f6d5;
    margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%,-50%);
}

.arrows {
    position: relative;
    transform: translateY(-85px);
    cursor: pointer;
    height: 50px;
    width: 50px;
    color: #60f6d5;
    transition: all .2s ease-in-out; 
}
.arrows:hover {
    transform: translateY(-85px) scale(1.2);;
}

@keyframes moveMain {
    0% {
        offset-distance: 0%;
        height: 100px;
        width: 100px;
    }
    100% {
        offset-distance: 100%;
        height: 70px;
        width: 70px;
    }
}

@keyframes moveMainIcon {
    0% {
        offset-distance: 0%;
        height: 70px;
        width: 70px;
    }
    100% {
        offset-distance: 100%;
        height: 49px;
        width: 49px;
    }
}

@keyframes moveGrowToMain {
    0% {
        offset-distance: 0%;
        height: 70px;
        width: 70px;
    }
    100% {
        height: 100px;
        width: 100px;
        offset-distance: 100%;
    }
}

@keyframes moveGrowToMainIcon {
    0% {
        offset-distance: 0%;
        height: 49px;
        width: 49px;
    }
    100% {
        offset-distance: 100%;
        height: 70px;
        width: 70px;
    }
}

@keyframes moveGrowToSide {
    0% {
        offset-distance: 0%;
        height: 60px;
        width: 60px;
    }
    100% {
        height: 70px;
        width: 70px;
        offset-distance: 100%;
    }
}

@keyframes moveGrowToSideIcon {
    0% {
        offset-distance: 0%;
        height: 42px;
        width: 42px;
    }
    100% {
        height: 49px;
        width: 49px;
        offset-distance: 100%;
    }
}

@keyframes moveFade {
    0% {
        offset-distance: 0%;
        height: 70px;
        width: 70px;
    }
    100% {
        height: 60px;
        width: 60px;
        offset-distance: 100%;
    }
}

@keyframes moveFadeIcon {
    0% {
        offset-distance: 0%;
        height: 49px;
        width: 49px;
    }
    100% {
        height: 42px;
        width: 42px;
        offset-distance: 100%;
    }
}