.List {
  background-color: gray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  /* background-image: url(../../public/dark-honeycomb.png);
  background-repeat:repeat; */
  font-family: 'Exo 2', sans-serif;
  vertical-align: middle;
  position: relative;
  text-align: center;

  margin-bottom: 50px;
}

@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);

:root {
  /* Base font size */
  font-size: 10px;   
  
  /* Set neon color */
  --neon-text-color: #f40;
  --neon-border-color: #08f;
}

.header h2.siteTitle {
  transform: translate(0, -10%);

  display: inline-block;
  font-size: min(5vw, 50px);
  font-weight: 200;
  font-style: italic;
  color: #fff;

  padding: 1rem 1rem 1rem;

  border: 0.4rem solid #fff;
  border-radius: 2rem;
  text-transform: uppercase;
  animation: flicker 2.2s infinite alternate;     

  margin-bottom: 0;
}

h2::-moz-selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

h2::selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

h2:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
    
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);
        
        box-shadow:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);        
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
        box-shadow: none;
    }    
}